
.pd3--base {



  // -- Drag and Drop Table
  & .dnd-table {
    border-spacing: 0px;
    border-collapse: separate;
    width: 100%;
    border-radius: var(--sz-sm);
    border: 1px solid var(--shade-06);

    & tr {
      &:first-child {

      & th:first-child {
        border-top-left-radius: var(--sz-sm);
      }
      & th:last-child {
        border-top-right-radius: var(--sz-sm);
      }
      }
      &:last-child {
        & td:first-child {
          border-bottom-left-radius: var(--sz-sm);
        }
        & td:last-child{
          border-bottom-right-radius: var(--sz-sm);
        }
      }
    }

    & th {
      background-color: var(--shade-14);
      @extend .pd-nm;
      @extend .txt-a-l;
      text-transform: uppercase;


    }

    & .child-tr {
      background-color: var(--shade-11);
      & td {
        border-top: 1px solid var(--shade-06);
        border-left: 1px solid var(--shade-06);
        @extend .pd-nm;

        &:first-child {
          border-left: 0;
        }
      }
    }


    & .droppable-tr {
      & td {
        box-sizing: border-box;
        height: 0px;
        padding: 0;
        max-height: 0.0001px;
        transition: background-color 0.3s ease-in-out, height 0.3s ease-in-out;
      }

      &--can-drop {
        // background-color: rgba(255,100,0,0.3);
        & td {
          height: 5px;
          //background-color: var(--shade-10);
          transition: all 0.3s ease-in-out;
        }
      }

      &--is-over {
        & td {
          background-color: var(--shade-10);
          padding-top: 45px;
          border: 2px dashed var(--green);
        }

      }
    }

    & .draggable-tr {
      background-color: var(--bg-base);
      & td {
        border-top: 1px solid var(--shade-06);
        border-left: 1px solid var(--shade-06);
        @extend .pd-nm;

        &:first-child {
          border-left: 0;
        }
      }

      &--dragging {
        display: none;
        & + .droppable-tr {
          display: none;
        }
      }
    }
  }


  // -- Form Components

  & .form-label {
    display: flex;

    &.position-top {
      flex-direction: column;
    }

    &.position-left {
      flex-direction: row;

      & .label-title {
        align-self: flex-start;
      }
    }

    & .label-title {
      @extend .c-bold;
      @extend .txt-lg;
      @extend .pd-b-xs;
      @extend .pd-r-nm;
    }

    & .form-label-error {
      padding-left: 3px;
    }
  }

  // Select Input from 'npm/react-select', as BEM styles

  & .form-select {
    &-container {
      outline: none !important;;
    }

    &__control {
      @extend .pd-l-xs;
      background-color: #fff;
      border: 1px solid var(--shade-05);
      transition: border 0.3s ease-in-out;
      border-radius: var(--sz-xs);
      @extend .txt-md;
      box-shadow: none;

      &--menu-is-open {
        outline: none !important;
        border: 1px solid var(--shade-03);
        transition: border 0.3s ease-in-out;
        box-shadow: none;
      }
    }

    &__input {

    }

    &__value-container {
    }

    &__menu {
      z-index: 2;
    }

    &__menu-list {

    }

    &__option {

    }
  }

  // DraftJS Wysiwyg Editor

  & .wysiwyg-textarea {
    margin-top: 5px;
    position: relative;
    @extend .c-bold;

    & .toggle-aa {
      margin-left: auto;
      border: 0;
      background-color: rgba(0, 0, 0, 0);
    }

    &:focus-within {
      & .wysiwyg-textarea__toolbar--full {
        border: 1px solid var(--shade-03);
        transition: border 0.3s ease-in-out;
      }
    }

    &__editor {
      min-height: 80px;
      background-color: #fff;
      border: 1px solid var(--shade-05);
      transition: all 0.3s ease-in-out;
      border-radius: var(--sz-xs);
      @extend .pd-h-nm;
      padding-bottom: 45px;


      &:focus-within {
        border: 1px solid var(--shade-03);
        transition: border 0.3s ease-in-out;
      }

      &--simple {
      }

      &--full {
      }
    }


    &__toolbar {
      width: calc(100% - 0px);
      box-sizing: border-box;
      border-bottom-left-radius: var(--sz-xs);
      border-bottom-right-radius: var(--sz-xs);
      //@extend .box-shadow-1r;
      position: absolute;
      right: 0px;
      bottom: -5px;
      background-color: rgba(0, 0, 0, 0.0);

      &--simple {
        border: 0;

        & .rdw-list-wrapper, & .rdw-inline-wrapper, & .rdw-block-wrapper, & .rdw-link-wrapper {
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.1s ease-in-out;
        }
      }

      &--full {
        & .rdw-list-wrapper, & .rdw-inline-wrapper, & .rdw-block-wrapper, & .rdw-link-wrapper {
          transition: opacity 0.1s ease-in-out;
          opacity: 1;
        }

        background-color: rgba(0, 0, 0, 0.01);
        border: 1px solid var(--shade-05);


      }
    }
  }



  // Input

  & .input-inline__wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    & .input-inline {
      margin: 0;
      padding: 0;
    }
  }

  & .form-input {

    @keyframes flashOutIcon {
      from {opacity: 0}
      40% {opacity: 0}
      50% {opacity: 1}
      80% {opacity: 1}
      to {opacity: 0}
    }

    background-color: #fff;
    border: 1px solid var(--shade-05);
    transition: border 0.3s ease-in-out;
    @extend .pd-v-xs;
    @extend .pd-h-nm;
    border-radius: var(--sz-xs);

    &.input__login {
      border: 0;
      @extend .box-shadow-2r;
      border-radius: 0;
      margin-left: 4px;

      &:focus {
        border: 0;
      }
    }

    &:focus {
      outline: none;
      border: 1px solid var(--shade-03);
      transition: border 0.3s ease-in-out;
    }

    &.input-inline {
      border: 0;
      @extend .c-nm;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 0;

      & + .icon {
        //visibility: hidden;
        opacity: 0;
        @extend .pd-r-nm;

        &.flash-out {
          animation-name: flashOutIcon;
          animation-duration: 1.8s;
          animation-delay: 0s;

        }

      }


      &:focus {
        @extend .c-bold;
        & + .icon.flash-in {
          opacity: 1;
        }
      }
    }
  }


}
//react-quill editor
.ql-editor{
  min-height:150px;
}
ol li[data-list="bullet"] {
  list-style-type: disc;
}

.ql-editor ol li[data-list="bullet"] {
  list-style-type: none;
}

[data-text="true"]{
  background-color: white !important;}
[data-offset-key]{
    color:rgb(0, 0, 0) !important; font-family: 'Lato', sans-serif !important;font-size: 14px !important;}