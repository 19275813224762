



h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

body.pd3--base {
  font-family: "Lato", sans-serif;
  @extend .txt-nm;
  @extend .c-nm;

  text-underline-offset: 2px;

  h1, h2, h3, h4, h5 {
    font-family: "Lato", sans-serif;
  }

  h1 {
    @extend .txt-xl;
    @extend .c-bold;
    @extend .txt-b900;
    letter-spacing: -0.025em;
  }

  h2 {
    @extend .txt-lg;
    @extend .c-bold;
  }

  h3 {
    @extend .txt-md;
  }

  h4 {
    @extend .txt-nm;
  }


  h5 {
    @extend .txt-sm;
  }

  p {
    @extend .txt-nm;
    @extend .c-nm;
  }

  hr {
    border-color: var(--shade-06);
  }

  & .link, & .link-alike {
    color: var(--shade-04);
    @extend .cursor-link;
    text-decoration: none;
    &.u {
      text-decoration: underline;
    }

    &.disabled {
      cursor: not-allowed;
      color: var(--shade-05);
    }
  }


  & .title {
    @extend .txt-b;
    @extend .c-bold;

    &--xl {
      @extend .txt-xl;
      text-decoration: underline;
      text-decoration-color: var(--shade-06);
      text-underline-offset: 10px;
      padding-bottom: var(--sz-md);
    }

    &--lg {
      @extend .txt-lg;
      text-decoration: underline;
      text-decoration-color: var(--shade-06);
      text-underline-offset: 10px;
      padding-bottom: var(--sz-md);
    }

    &--md {
      @extend .txt-md;
    }

    &--nm {
      @extend .txt-nm;
    }

    &--sm {
      @extend .txt-sm;
    }

  }

}
