
.pd3--base {
  .spin {

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    &-5000 {
      animation-duration: 5000ms;
      animation-name: spin;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &-1000 {
      animation-duration: 1000ms;
      animation-name: spin;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &-600 {
      animation-duration: 600ms;
      animation-name: spin;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}



.pd3--base {
  .steps {

    /**
     * Snippets from animate.css
     * Credit goes to https://github.com/daneden
     * github.com/daneden/animate.css
    */

    & .step {

      animation-duration: .4242s;
      animation-fill-mode: backwards;
    }

    /** fadeInRight */
    @-webkit-keyframes fadeInRight {
      from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }

    @keyframes fadeInRight {
      from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }

    .fadeInRight {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
    }

    /** fadeInLeft */
    @-webkit-keyframes fadeInLeft {
      from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }

    @keyframes fadeInLeft {
      from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }

    .fadeInLeft {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
    }

    /** fadeOutRight */
    @-webkit-keyframes fadeOutRight {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }
    }

    @keyframes fadeOutRight {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }
    }

    .fadeOutRight {
      -webkit-animation-name: fadeOutRight;
      animation-name: fadeOutRight;
    }

    /** fadeOutLeft */
    @-webkit-keyframes fadeOutLeft {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }

    @keyframes fadeOutLeft {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }

    .fadeOutLeft {
      -webkit-animation-name: fadeOutLeft;
      animation-name: fadeOutLeft;
    }

  }
}
