
.pd3--base {

  & .popout-tippy {
    background-color: var(--bg-base);
    padding: 0;
  }


  & .popout-card {
    min-width: 300px;

    &__title {
      @extend .pd-nm;
      background-color: var(--shade-01);
      color: #fff;
      font-style: normal;
      @extend .txt-b;
      border-top-left-radius: var(--sz-xs);
      border-top-right-radius: var(--sz-xs);
    }

    &__body {
      @extend .pd-nm;
    }
  }



  & .dropzone {
    width: 100%;
    border: 2px dashed var(--shade-06);
    @extend .pd-nm;
    box-sizing: border-box;
    &--droppable {
      transition: all 0.1s ease-in-out;
      border-color: var(--green);
    }
  }

  // Data Table

  & .data-table {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: var(--sz-xs);
    border: 1px solid var(--shade-06);

    &:empty {
      border: 0;
    }


    &__child-wrapper {
      background-color: var(--shade-14);
      & .summary-card {
        border-color: var(--shade-04);
        background-color: var(--bg-base);
      }
    }


    &__headrow {
      border-bottom: 1px solid var(--shade-06);
      display: grid;
      width: 100%;
      background-color: var(--shade-11);
      border-top-left-radius: var(--sz-xs);
      border-top-right-radius: var(--sz-xs);


      & .cell {
        @extend .pd-v-nm;
        @extend .pd-h-nm;
        text-transform: uppercase;

        &:first-of-type {
          border-top-left-radius: var(--sz-xs);
        }

        &:last-of-type {
          border-top-right-radius: var(--sz-xs);
        }

        &:empty {
          display: none;
        }
      }

      &--slim {
        & .cell {
          padding: var(--sz-xs) !important;
          @extend .txt-sm;
        }
      }
    }

    @keyframes Row_open {
      from {
        max-height: 0px;
        opacity: 0;
      }

      to {
        max-height: 400px;
      }
    }

    &__row {
      display: grid;
      width: 100%;
      box-sizing: border-box;
      animation-name: Row_open;
      animation-duration: 1s;
      border-bottom: 1px solid var(--shade-06);
      &:nth-last-of-type(1) {
        border-bottom-color: rgba(0,0,0,0);
      }




      &--is-dragging {
        visibility: hidden;
        display: none;
      }

      & .cell {
        display: flex;
        align-items: center;
        @extend .pd-v-nm;
        @extend .pd-h-nm;

        &:empty {
          display: none;
        }
      }


      &:last-of-type {
        &:first-of-type {
          border-bottom-left-radius: var(--sz-xs);
        }

        &:last-of-type {
          border-bottom-right-radius: var(--sz-xs);
        }
      }

      &--child {
        &.level-1 {
          background-color: var(--shade-14);
          padding-left: 10px
        }

        &.level-2 {
          background-color: var(--shade-13);
          padding-left: 20px
        }

        &.level-3 {
          background-color: var(--shade-12);
          padding-left: 30px
        }

        &.level-4 {
          background-color: var(--shade-11);
          padding-left: 40px
        }

        &.level-5 {
          background-color: var(--shade-14);
          padding-left: 50px
        }

      }

      &--no-hierarchy {
        &.level-1 {
          padding-left: 0;
        }
      }
    }

    &__droprow-between {
      display: block;
      z-index: 3;
      width: 100%;
      height: 20px;
      background-color: rgba(200, 0, 0, 0);
      margin-bottom: -20px;
      transition: all 0.2s ease-in-out 0.1s;
      &--parent-dragging {
        z-index: -1;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        transition: all 0ms;
      }

      &--droppable {
        background-color: var(--shade-06);
        padding-bottom: 40px;
        margin-bottom: 0px;
        height: 0px;
        border: 2px dashed var(--green);
        box-sizing: border-box;
      }
    }

    &__droprow-below {
      display: flex;
      align-items: baseline;
      z-index: 3;
      width: calc(100% - 50px);
      height: 20px;
      background-color: rgba(0, 0, 0, 0);
      margin-top: -20px;
      margin-left: 50px;
      transition: all 0.2s ease-in-out 0.1s;

      &--parent-dragging {
        z-index: -1;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        transition: all 0ms;
      }

      &--droppable {
        padding-bottom: 40px;

        &:after {
          display: block;
          content: '';
          width: 100%;
          height: 40px;
          margin-top: 20px;
          background-color: var(--shade-09);
          border: 2px dashed var(--green);
          box-sizing: border-box;
        }
      }
    }


  }


  & .accordion-item {
    margin-bottom: -1px;
    &__header {
      @extend .cursor-link;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      border: 1px solid var(--shade-06);
      @extend .pd-nm;

      & .title {
        @extend .c-bold;
        @extend .txt-md;
      }

      & .subtitle {

      }

      & .icon {
        float: right;
      }
    }

    &__body {
      border-left: 1px solid var(--shade-06);
      border-right: 1px solid var(--shade-06);
      border-bottom: 1px solid var(--shade-06);
      @extend .pd-h-nm;

      &--open {
      }
    }


  }


  & .badge {
    background-color: var(--shade-14);
    display: inline-flex;
    border-radius: 100px;
    @extend .pd-h-sm;
    justify-content: center;
    align-items: center;
    padding-top: var(--sz-xxs);
    padding-bottom: var(--sz-xxs);
    @extend .c-bold;

    &__group {
      padding: 0;

      & .badge {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    & .icon {
      padding-bottom: 5px;
      @extend .pd-r-xs;
      margin-left: -5px;
    }

    & .icon.right {
      padding-bottom: 5px;
      @extend .pd-r-xs;
      margin-left: 5px;
      margin-right: -5px;
    }

    &--danger, &--red {
      background-color: var(--red-shaded);
      @extend .c-inv;
    }

    &--success, &--green {
      background-color: var(--green);
      @extend .c-inv;
    }

    &--warn, &--yellow {
      background-color: var(--yellow);
      @extend .c-bold;
    }

  }

  // DataList

  & .data-list {
    & tr {
      & td {
        @extend .c-nm;

        &:first-of-type {
          @extend .pd-r-md;
        }
      }
    }

  }


  // Status Icon

  & .stat {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    & .icon {
    }

    & span {
      @extend .c-bold;
      @extend .pd-t-nm;
    }
  }


  // Summary Card

  & .summary-card {
    border: 1px solid var(--shade-06);
    border-radius: var(--sz-sm);


    &.fav-summary-card {
      // Defined heights

    }
  }

  // Stepper

  & .tab-navigator {
    display: flex;
    background-color: var(--shade-11);
    justify-content: space-between;
    border-top: 2px solid var(--shade-11);
    border-top-left-radius: var(--sz-xs);
    border-top-right-radius: var(--sz-xs);
    @extend .mg-b-xl;

    &__tab {
      &:first-of-type {
        &.tab-navigator__tab--active {
          border-top-left-radius: 0;
        }
      }

      &:last-of-type {
        &.tab-navigator__tab--active {
          border-top-right-radius: 0;
        }
      }

      display: flex;
      border-top-left-radius: var(--sz-xs);
      border-top-right-radius: var(--sz-xs);
      background-color: var(--shade-11);
      flex: 1;
      justify-content: center;
      @extend .pd-h-nm;
      @extend .pd-v-sm;
      @extend .cursor-link;

      &--active {
        transition: all 0.3s ease-in-out;
        background-color: var(--bg-base);

      }
    }

  }

  & .step-navigator {
    @extend .mg-t-nm;
    @extend .mg-b-lg;
    display: flex;
    justify-content: space-between;
    @extend .pd-h-nm;
    @extend .pd-v-sm;


    &__spacer {
      background-color: var(--shade-05);
      border-radius: 3px;
      flex: 1;
      height: 3px;
      align-self: center;
      @extend .mg-h-nm;
    }

    border-radius: var(--sz-xs);
    border: 1px solid var(--shade-06);

    &__step {
      background-color: var(--bg-base);
      z-index: 2;
      width: var(--sz-xl);
      height: var(--sz-xl);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid var(--shade-06);

      & .mark {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: var(--shade-06);
      }

      &--can-goto {
        border-color: var(--shade-04);
        @extend .cursor-link;
      }

      &--active {
        @extend .txt-b;
        border-color: var(--teal);
        color: var(--teal);

        & .mark {
          background-color: var(--teal);
        }
      }

      &--complete {
        border-color: var(--green);
      }


    }

    &__title {
      text-transform: uppercase;
      @extend .pd-l-sm;
      align-self: center;

      &--can-goto {
        @extend .c-bold;
        @extend .cursor-link;
      }
    }
  }

  & .steps {
    padding-left: var(--sz-xl);
    margin-left: calc(-1 * var(--sz-xl));
    // Animations defined in _animations.scss
    position: relative;
    overflow: hidden;

    & .step {
      z-index: 2;
      width: 100%;
      height: 100%;

      &--active {
        opacity: 1;
        position: relative;
      }

      &--inactive {
        position: absolute;
        top: 0;
        opacity: 0;
        pointer-events: none;
        z-index: 0;
        background-color: var(--bg-base);
      }
    }
  }


  // -- Components

  // SectionTitle
  & .section-title h1 {
    @extend .txt-xl;
    @extend .txt-b900;
    @extend .c-bold;
    @extend .pd-v-sm;
    width: 100%;
    @extend .mg-t-sm;
    @extend .mg-b-lg;
    border-bottom: 2px solid var(--shade-06);

    & .section-subtitle {

    }
  }


  // Icon
  & .icon {
    &.icon--flip {
      transform: scaleX(-1);
    }
  }


  // Buttons

  & .button-group {

    &--split {
      & .btn {
        @extend .mg-r-nm;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &--dense {
      & .btn {
        // border-right: 1px solid var(--shade-03);
        border-radius: 0;

        &:last-of-type {
          border-right: 0;
          border-top-right-radius: var(--sz-xs);
          border-bottom-right-radius: var(--sz-xs);
        }
        &:first-of-type {
          border-top-left-radius: var(--sz-xs);
          border-bottom-left-radius: var(--sz-xs);
        }
      }
    }
  }

  & .btn {

    &-primary, &-secondary, &-light {
      border: 1px solid var(--shade-05);
      background-color: var(--shade-09);
      @extend .pd-v-xs;
      @extend .pd-h-nm;
      @extend .cursor-link;
      border-radius: var(--sz-xs);
      transition: filter 0.3s ease-in-out;

      &:hover {
        filter: brightness(120%);
        transition: filter 0.3s ease-in-out;
      }

      & .icon {
        @extend .pd-r-xs;
        margin-left: calc(-1 * var(--sz-xs))
      }

      &:disabled {
        cursor: not-allowed;
        background-color: var(--shade-06);
        @extend .c-nm;
      }
    }


    &-primary {
      background-color: var(--shade-01);
      @extend .c-inv;

      &:disabled:hover {
        filter: brightness(110%);
        transition: filter 0.3s ease-in-out;
      }

      &:hover {
        filter: brightness(160%);
        transition: filter 0.3s ease-in-out;
      }
    }

    &-secondary {
      background-color: var(--shade-03);
      @extend .c-inv;

      &:hover {
        filter: brightness(120%);
        transition: filter 0.3s ease-in-out;
      }
    }

    &-light {
      border-color: var(--shade-11);
      background-color: var(--shade-11);
      color: var(--shade-03);
      &:hover {

        border-color: var(--shade-07);
        filter: brightness(90%);
        transition: filter 0.3s ease-in-out;
      }
    }
  }
}
