
.pd3--base {

  & .toggle-nav, & .left-nav {
    & .navigation {
      & hr {
        border: 0.1px solid var(--shade-06);
      }

      &--sm, &--nm {
        & ul.nav li a.active {
          background-color: var(--shade-03);
          color: #fff !important;
          border-radius: var(--sz-sm);
          margin-left: calc(-1 * var(--sz-xs));
          padding-left: var(--sz-nm) !important;
          &:hover {
            background-color: var(--shade-02) !important;
          }
        }
      }

      & .nav-accordion {
        overflow: hidden;
      }

      & section.nav-section {
        @extend .mg-l-md;

        &.icon {
          margin-left: 0;

          & ul.nav {
            box-sizing: border-box;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        & h2 {
          @extend .txt-lg;
          font-weight: bold;
          @extend .c-bold;
        }

        & ul.nav {
          @extend .mg-t-nm;
          @extend .pd-l-xs;
          @extend .pd-b-sm;
          list-style: none;

          & li {

            & a {
              display: block;
              text-decoration: none;
              border-left: 2px solid rgb(230, 230, 230);
              @extend .c-nm;
              @extend .pd-xs;
              @extend .txt-md;

              &:visited {
                @extend .c-nm;
              }

              &:hover {
                background-color: var(--shade-07);
                transition: background-color 0.3s ease-in-out;
              }

              &.active {
                border-left: 3px solid var(--shade-01);
                @extend .txt-b;
                @extend .c-bold;
              }
            }
          }
        }
      }
    }
  }

  & .toggle-nav {

    position: fixed;
    left: 0;
    height: calc(100vh - var(--page-header-height));
    max-height: calc(100vh - var(--page-header-height));
    width: 100%;
    z-index: 14;
    top: var(--page-header-height);

    &.toggle-nav--open {
      background-color: rgba(17, 24, 39, 0.4);
    }

    transition: left 0.3s ease-out, background-color 0.3s ease-in 0.3s;

    &.toggle-nav--closed {
      left: -200%;
      transition: left 0.3s ease-in 0.3s, background-color 0.3s ease-in;
    }


    & .navigation {
      overflow-y: auto;
      pointer-events: all;
      width: 340px;
      height: 100%;
      @extend .pd-l-lg;
      @extend .pd-r-md;
      box-sizing: border-box;
      background-color: var(--shade-01);

      & hr {
        display: none;
      }

      & hr:first-of-type {
        display: block;
        border: 1px solid var(--shade-03);
      }

      & .icon path {
        fill: #fff;
      }

      & section.nav-section {
        @extend .mg-l-md;

        & h2 {
          @extend .c-inv;
        }

        @extend .pd-t-sm;

        & ul.nav {
          & li {

            & a {

              &:hover {
                background-color: var(--shade-01);
                transition: background-color 0.3s ease-in-out;
              }

              &.active {
                // border-right: 3px solid var(--shade-04);
                @extend .txt-b;
                color: var(--shade-03);
              }
            }
          }
        }
      }
    }
  }

}
