
body.pd3--base {
  //background-color: var(--bg-base);


  background: linear-gradient(180deg, var(--top-bar) 0,
          var(--top-bar) var(--page-header-height),
          var(--bg-base) var(--page-header-height),
          var(--bg-base) 100%);
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;

  & div.layout {
    display: grid;
    grid-template-columns: var(--left-nav-width) 1fr;
    grid-template-rows: var(--page-header-height) 1fr;
    height: 100vh;
    // width: 100vw;

    & .nav-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;

      & .icon {
      }
    }

    &.ss--lg {
      width: 1920px;
      margin: 0 auto;
    }

    &.ss--nm, &.ss--sm {
      grid-template-columns: 1fr;
      grid-template-rows: var(--page-header-height) 1fr;

      & header.page-head {
        position: fixed;
        z-index: 14;
        width: 100%;
        height: var(--page-header-height);
      }

      & main.content {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        @extend .pd-sm;
        border-left: 0
      }
    }

    &.layout--simple {
      & main.content {
        border-left: 0;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    & header.page-head {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      background-color: var(--top-bar);

      display: flex;
      flex-direction: row;

      & .logo {
        width: var(--left-nav-width);
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          width: 230px;
        }
      }

      & .search {
        @extend .pd-l-xl;
        display: flex;
        flex: 1;
        align-items: center;
      }

      & .profile {
        width: 100px;
      }
    }

    & .left-nav {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      @extend .pd-nm;
    }


    & main.content {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      max-width: 1195px;
      box-sizing: border-box;
      @extend .pd-l-xl;
      @extend .pd-t-sm;
      @extend .pd-r-xl;
      @extend .pd-b-xl;
      border-left: 1px solid var(--shade-05);
    }
  }


  // Page Search

  & .page-search {
    & .page-search-input {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      @extend .pd-sm;
      @extend .pd-l-xl;
      margin-left: calc(-1 * var(--sz-lg));
      border-radius: var(--sz-xs);
      border: 0px solid rgba(0, 0, 0, 0);
      transition: border 0.3s ease-in-out;
      &.users-page{
        border: 1px solid #D1D5DB;
      }
      &:focus {
        border: 1px solid var(--shade-03);
        border-radius: var(--sz-xs);
        outline: none;
        transition: border 0.3s ease-in-out;
      }
    }
  }

  // Profile Pic

  & .profile {
    position: relative;
    @extend .cursor-link;

    & .profile-menu {

      & .pm-menu {
        z-index: 10001;
        @extend .pd-l-nm;
        @extend .pd-r-nm;
        @extend .box-shadow-1r;
        border-radius: var(--sz-sm);
        position: absolute;
        top: 50px;
        right: 85px;
        border: 1px solid var(--shade-06);
        width: 200px;
        background-color: var(--bg-base);

        & .pm-section {
          border-bottom: 1px solid var(--shade-06);
          display: flex;
          flex-direction: column;
          @extend .pd-t-sm;

          & span.label {
            cursor: initial;
          }

          & .no-icon {
            padding-left: 28px;
          }

          & a, div.logout-form, span.label, div.switch-tenant-form {
            & .icon {
              @extend .mg-r-sm;
            }

            @extend .c-bold;
            @extend .pd-b-sm;
            text-decoration: none;
            @extend .txt-md;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    & .profile-picture {
      background-color: #fff;
      border-radius: 50%;
    }
  }


  // Sections

  & .section {
    @extend .mg-b-lg;
    &--isLoading {
      display: flex;
      justify-content: center;
      @extend .mg-t-lg;
    }
  }

  & .control-section {
    display: flex;
    justify-content: end;
  }

  // Grids

  & .grid {
    display: grid;

    &.cg-none {
      column-gap: 0
    }

    &.rg-none {
      row-gap: 0
    }

    &.cg-xs {
      column-gap: var(--sz-xs)
    }

    &.rg-xs {
      row-gap: var(--sz-xs)
    }

    &.cg-sm {
      column-gap: var(--sz-sm)
    }

    &.rg-sm {
      row-gap: var(--sz-sm)
    }

    &.cg-nm {
      column-gap: var(--sz-nm)
    }

    &.rg-nm {
      row-gap: var(--sz-nm)
    }

    &.cg-md {
      column-gap: var(--sz-md)
    }

    &.rg-md {
      row-gap: var(--sz-md)
    }

    &.cg-lg {
      column-gap: var(--sz-lg)
    }

    &.rg-lg {
      row-gap: var(--sz-lg)
    }

    &.cg-xl {
      column-gap: var(--sz-xl)
    }

    &.rg-xl {
      row-gap: var(--sz-xl)
    }

    &.cg-2xl {
      column-gap: var(--sz-2xl)
    }

    &.rg-2xl {
      row-gap: var(--sz-2xl)
    }


    & .grid-element {

    }
  }

  // Center Hoizontally

  & .center-h {
    display: flex;
    justify-content: center;

    &--col {
      align-items: center;
      flex-direction: column;
    }
  }


}
