

.pd3--base {
  --bg-base: #fff;

  // Layout:

  --page-header-height: 64px;
  --left-nav-width: 270px;


  --txt-sm: 12px;
  --txt-nm: 14px;
  --txt-md: 16px;
  --txt-lg: 20px;
  --txt-xl: 30px;
  --lh-sm: 20px;
  --lh-nm: 20px;
  --lh-md: 24px;
  --lh-lg: 24px;
  --lh-xl: 40px;



  --sz-xxs: 4px;
  --sz-xs: 8px;
  --sz-sm: 12px;
  --sz-nm: 16px;
  --sz-md: 24px;
  --sz-lg: 32px;
  --sz-xl: 48px;
  --sz-2xl: 80px;


  --shade-01: #111827;
  --shade-02: #1f2937;
  --shade-03: #6b7280;
  --shade-04: #9a9eb1;
  --shade-05: #d1d5db;
  --shade-06: #e5e7db;
  --shade-07: #e8eaed;
  --shade-08: #edeff0;
  --shade-09: #e8e8e8;
  --shade-10: #f3f5f6;
  --shade-11: #f3f4f6;
  --shade-12: #f8f8f8;
  --shade-13: #f8f9fa;
  --shade-14: #f9fafb;
  --shade-15: #f6f9ff;
  --font-dark: var(--shade-01);
  --font-default: var(--shade-03);
  --top-bar: var(--shade-02);
  --font-invert: #fff;

  --prim-01: #374151;
  --prim-02: #18488a;
  --prim-03: #62a4ca;
  --prim-04: #47b857;
  --prim-05: #f4c403;
  --prim-06: #fde291;
  --prim-07: #d63d4f;
  --prim-08: #f85252;

  --grey-dark: var(--prim-01);
  --blue: var(--prim-02);
  --teal: var(--prim-03);
  --green: var(--prim-04);
  --yellow: var(--prim-05);
  --yellow-pastel: var(--prim-06);
  --red-full: var(--prim-07);
  --red-shaded: var(--prim-08);


}




.bg {
  &-01 {background-color: var(--shade-01)}
  &-02 {background-color: var(--shade-02)}
  &-03 {background-color: var(--shade-03)}
  &-04 {background-color: var(--shade-04)}
  &-05 {background-color: var(--shade-05)}
  &-06 {background-color: var(--shade-06)}
  &-07 {background-color: var(--shade-07)}
  &-08 {background-color: var(--shade-08)}
  &-09 {background-color: var(--shade-09)}
  &-10 {background-color: var(--shade-10)}
  &-11 {background-color: var(--shade-11)}
  &-12 {background-color: var(--shade-12)}
  &-13 {background-color: var(--shade-13)}
  &-14 {background-color: var(--shade-14)}
  &-15 {background-color: var(--shade-15)}
}

.vis {
  &-v {visibility: visible};
  &-c {visibility: collapse};
  &-h {visibility: hidden};
}

.w {
  &-100 {width: 100%}
}

.bs { // Block Size
  &-xs {width: var(--sz-xs); height: var(--sz-xs);}
  &-sm {width: var(--sz-sm); height: var(--sz-sm);}
  &-nm {width: var(--sz-nm); height: var(--sz-nm);}
  &-md {width: var(--sz-md); height: var(--sz-md);}
  &-lg {width: var(--sz-lg); height: var(--sz-lg);}
  &-xl {width: var(--sz-xl); height: var(--sz-xl);}
}

.txt {
  &-sm {font-size: var(--txt-sm); line-height: var(--lh-sm);}
  &-nm {font-size: var(--txt-nm); line-height: var(--lh-nm);}
  &-md {font-size: var(--txt-md); line-height: var(--lh-md);}
  &-lg {font-size: var(--txt-lg); line-height: var(--lh-lg);}
  &-xl {font-size: var(--txt-xl); line-height: var(--lh-xl);}

  &-b {font-weight: bold}
  &-b700 {font-weight: 700}
  &-b900 {font-weight: 900}

  &-i {font-style: italic}
  &-u {text-decoration: underline !important;}

  &-a {
    &-r {text-align: right}
    &-l {text-align: left}
    &-c {text-align: center}
  }
}

.lh {
  &-sm {line-height: var(--lh-sm)}
  &-nm {line-height: var(--lh-nm)}
  &-md {line-height: var(--lh-md)}
  &-lg {line-height: var(--lh-lg)}
  &-xl {line-height: var(--lh-xl)}
}




.d {
  &-b {display: block}
  &-f {display: flex}
  &-i {display: inline}
  &-ib {display: inline-block}
}


.c {
  &-nm {color: var(--font-default)}
  &-bold {color: var(--font-dark)}
  &-inv {color: var(--font-invert)}
  &-danger {color: var(--red-full)}
}

.pd {
  &-0 {padding: 0 !important;}
  &-xs {padding: var(--sz-xs)}
  &-sm {padding: var(--sz-sm)}
  &-nm {padding: var(--sz-nm)}
  &-md {padding: var(--sz-md)}
  &-lg {padding: var(--sz-lg)}
  &-xl {padding: var(--sz-xl)}
  &-r {
    &-xs {padding-right: var(--sz-xs)}
    &-sm {padding-right: var(--sz-sm)}
    &-nm {padding-right: var(--sz-nm)}
    &-md {padding-right: var(--sz-md)}
    &-lg {padding-right: var(--sz-lg)}
    &-xl {padding-right: var(--sz-xl)}
  }
  &-l {
    &-xs {padding-left: var(--sz-xs)}
    &-sm {padding-left: var(--sz-sm)}
    &-nm {padding-left: var(--sz-nm)}
    &-md {padding-left: var(--sz-md)}
    &-lg {padding-left: var(--sz-lg)}
    &-xl {padding-left: var(--sz-xl)}
  }
  &-t {
    &-xs {padding-top: var(--sz-xs)}
    &-sm {padding-top: var(--sz-sm)}
    &-nm {padding-top: var(--sz-nm)}
    &-md {padding-top: var(--sz-md)}
    &-lg {padding-top: var(--sz-lg)}
    &-xl {padding-top: var(--sz-xl)}
  }
  &-b {
    &-xs {padding-bottom: var(--sz-xs)}
    &-sm {padding-bottom: var(--sz-sm)}
    &-nm {padding-bottom: var(--sz-nm)}
    &-md {padding-bottom: var(--sz-md)}
    &-lg {padding-bottom: var(--sz-lg)}
    &-xl {padding-bottom: var(--sz-xl)}
  }
  &-h {
    &-xs {padding-left: var(--sz-xs);padding-right: var(--sz-xs);}
    &-sm {padding-left: var(--sz-sm);padding-right: var(--sz-sm);}
    &-nm {padding-left: var(--sz-nm);padding-right: var(--sz-nm);}
    &-md {padding-left: var(--sz-md);padding-right: var(--sz-md);}
    &-lg {padding-left: var(--sz-lg);padding-right: var(--sz-lg);}
    &-xl {padding-left: var(--sz-xl);padding-right: var(--sz-xl);}
  }
  &-v {
    &-xs {padding-top: var(--sz-xs);padding-bottom: var(--sz-xs);}
    &-sm {padding-top: var(--sz-sm);padding-bottom: var(--sz-sm);}
    &-nm {padding-top: var(--sz-nm);padding-bottom: var(--sz-nm);}
    &-md {padding-top: var(--sz-md);padding-bottom: var(--sz-md);}
    &-lg {padding-top: var(--sz-lg);padding-bottom: var(--sz-lg);}
    &-xl {padding-top: var(--sz-xl);padding-bottom: var(--sz-xl);}
  }

}

.mg {
  &-xs {margin: var(--sz-xs)}
  &-sm {margin: var(--sz-sm)}
  &-nm {margin: var(--sz-nm)}
  &-md {margin: var(--sz-md)}
  &-lg {margin: var(--sz-lg)}
  &-xl {margin: var(--sz-xl)}

  &-l-a {margin-left: auto}
  &-r-a {margin-right: auto}
  &-h-a {margin-left: auto; margin-right: auto;}

  &-r {
    &-xs {margin-right: var(--sz-xs)}
    &-sm {margin-right: var(--sz-sm)}
    &-nm {margin-right: var(--sz-nm)}
    &-md {margin-right: var(--sz-md)}
    &-lg {margin-right: var(--sz-lg)}
    &-xl {margin-right: var(--sz-xl)}
  }
  &-l {
    &-xs {margin-left: var(--sz-xs)}
    &-sm {margin-left: var(--sz-sm)}
    &-nm {margin-left: var(--sz-nm)}
    &-md {margin-left: var(--sz-md)}
    &-lg {margin-left: var(--sz-lg)}
    &-xl {margin-left: var(--sz-xl)}
  }
  &-t {
    &-xs {margin-top: var(--sz-xs)}
    &-sm {margin-top: var(--sz-sm)}
    &-nm {margin-top: var(--sz-nm)}
    &-md {margin-top: var(--sz-md)}
    &-lg {margin-top: var(--sz-lg)}
    &-xl {margin-top: var(--sz-xl)}
  }
  &-b {
    &-xs {margin-bottom: var(--sz-xs)}
    &-sm {margin-bottom: var(--sz-sm)}
    &-nm {margin-bottom: var(--sz-nm)}
    &-md {margin-bottom: var(--sz-md)}
    &-lg {margin-bottom: var(--sz-lg)}
    &-xl {margin-bottom: var(--sz-xl)}
  }
  &-h {
    &-a {margin-left: auto; margin-right: auto}
    &-xs {margin-left: var(--sz-xs);margin-right: var(--sz-xs);}
    &-sm {margin-left: var(--sz-sm);margin-right: var(--sz-sm);}
    &-nm {margin-left: var(--sz-nm);margin-right: var(--sz-nm);}
    &-md {margin-left: var(--sz-md);margin-right: var(--sz-md);}
    &-lg {margin-left: var(--sz-lg);margin-right: var(--sz-lg);}
    &-xl {margin-left: var(--sz-xl);margin-right: var(--sz-xl);}
  }
  &-v {
    &-xs {margin-top: var(--sz-xs);margin-bottom: var(--sz-xs);}
    &-sm {margin-top: var(--sz-sm);margin-bottom: var(--sz-sm);}
    &-nm {margin-top: var(--sz-nm);margin-bottom: var(--sz-nm);}
    &-md {margin-top: var(--sz-md);margin-bottom: var(--sz-md);}
    &-lg {margin-top: var(--sz-lg);margin-bottom: var(--sz-lg);}
    &-xl {margin-top: var(--sz-xl);margin-bottom: var(--sz-xl);}
  }
}


// Flex

.f {
  &-d-r {flex-direction: row}
  &-d-c {flex-direction: column}

  &-jc-sa {justify-content: space-around}
  &-jc-sb {justify-content: space-between}
  &-jc-fe {justify-content: flex-end}
  &-jc-c {justify-content: center}

  &-ai-fe {align-items: flex-end}
  &-ai-c {align-items: center}

  &-1 {
    flex: 1;
  }
}

// float

.fl {
  &-r {float: right}
  &-l {float: left}
}

// box-sizing
.box-sizing {
  &-b {box-sizing: border-box}
  &-c {box-sizing: content-box}
}

.cursor-link {cursor: pointer}
.cursor-dnd {cursor: move; cursor: grab;}

.ws-nw {white-space: nowrap}
