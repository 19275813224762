


// Via: https://l2zm8.csb.app/

.box-shadow-1r {
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.box-shadow-2r {
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2);
}
.box-shadow-3r {
  box-shadow: 0 5px 15px hsla(0, 0%, 0%, 0.2);
}
.box-shadow-4r {
  box-shadow: 0 10px 24px hsla(0, 0%, 0%, 0.2);
}
.box-shadow-5r {
  box-shadow: 0 15px 35px hsla(0, 0%, 0%, 0.2);
}



