.rs-picker-daterange-content, .rs-picker-toolbar {
    min-width: 340px;
}
.rs-picker-menu {
    z-index: 10000;
}

.quill {
    background: #fff;
}
