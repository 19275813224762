/*


    & .y-label-wrap {
      position: relative;
      display: block;
    }

    & .y-label {
      display: block;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      position: absolute;
      left: -23%;
      top: 46%;
      text-align: center;
      @extend .mg-r-md;
    }



 */

.pd3--base {

  & .gauge-chart {

    & .needle {
      display: none;
    }

    & .text-group {
      font-weight: bold;
      font-size: 40px !important;
    }
  }

  & .timeline-chart__label-today {

  }
  & .timeline-chart {

    --tlc-height: 21px;
    --tlc-today-height: 21px;
    --tlc-today-del-height: 15px;
    --tlc-c-planned: #000;
    --tlc-c-future: var(--shade-03);
    --tlc-c-tolerance: var(--yellow);
    --tlc-c-overdue: var(--red-full);
    --tlc-c-today: #15B5F2;

    width: 100%;
    margin-top: 40px;

    & .chart {
      margin: 20px 0 10px 0;
      display: flex;
      height: var(--tlc-height);
      flex-direction: row;
    }

    & .future-indicator {
      width: 60px;

      &:before {
        display: block;
        content: '';
        // background-color: var(--tlc-c-planned);
        background-color: #fff;
        background-image:
                radial-gradient(circle, var(--tlc-c-future) 15%, transparent 10%),
                radial-gradient(circle, var(--tlc-c-future) 15%, transparent 10%);
        background-size: 8px 30px;
        background-position: 0 0, 57px 48px;


        width: 60px;
        height: 6px;
        margin-top: calc((var(--tlc-height) - 6px) / 2);
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 3px;
      }
    }


    & .today {
      z-index: 2;
      display: block;
      width: var(--tlc-today-height);
      height: var(--tlc-today-height);
      border-radius: var(--tlc-today-height);
      background-color: var(--tlc-c-today);
      margin-top: calc((var(--tlc-height) + 5px) / 2 * -1);
      //margin-left: calc(10% - 20px);

    }

    & .labels {
      display: flex;
      & .label {
        pointer-events: none;
        &__end {
          justify-content: space-between;
          display: flex;
          height: 40px;
          text-align: right;
          padding-right: 20px;
          & .left {
            padding-left: 4px;
          }
          & .right {
            margin-right: -16px;
          }
        }
        &__tolerance {
          height: 40px;
          text-align: right;
          padding-right: 20px;
          & div {
            margin-right: -16px;
          }
        }
        &__overdue {
          height: 40px;
          text-align: right;
          padding-right: 20px;
          & div {
            margin-right: -16px;
          }
        }
        &__future-indicator {
          width: 60px;
        }
      }

    }

    & .delimiter {
      width: 3px;
      height: var(--tlc-height);
      background-color: var(--tlc-c-planned);
      border-radius: 3px;
      z-index: 2;

      &--is-today {
        &:after {
          display: block;
          content: '';
          width: var(--tlc-today-height);
          height: var(--tlc-today-height);
          margin-top: 0px;
          margin-left: calc((var(--tlc-today-height) / 2 * -1));
          border-radius: var(--tlc-today-height);
          background-color: var(--tlc-c-today);
        }
      }

      &__start {
        background-color: var(--tlc-c-planned);
      }

      &__end {
        background-color: var(--tlc-c-planned);
      }

      &__tolerance {
        background-color: var(--tlc-c-tolerance);
      }


    }

    & .timeline {
      flex: 1;
      padding-right: 20px;

      &:before {
        display: block;
        content: '';
        background-color: var(--tlc-c-planned);
        width: calc(100% - 6px + 20px);
        height: 3px;
        margin-top: calc((var(--tlc-height) - 3px) / 2);
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 3px;
      }

      &__planned {
        &:before {
          background-color: var(--tlc-c-planned);
        }
      }

      &__tolerance {
        &:before {
          background-color: var(--tlc-c-tolerance);
        }
      }

      &__overdue {
        &:before {
          background-color: var(--tlc-c-overdue);
        }

        & .today {
          margin-left: 100% !important;
        }
      }


    }

  }

  & .ss--sm .block-chart {
    margin-left: -20px;
  }
  & .block-chart {

    padding-top: 30px;
    padding-right: 0px;



    & .y-label-wrap {
      display: flex;
    }

    & .y-label {
      display: block;
      writing-mode: tb-rl;
      transform: rotate(180deg);

      text-align: center;
      @extend .mg-r-md;
    }

    & .x-label {
      display: block;
      text-align: center;
      @extend .mg-t-nm;
      @extend .mg-l-nm;
      @extend .pd-l-md;
    }

    & .block {
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      & .block-label {
        line-height: 16.12px;
        background-color: #fff;
        @extend .pd-v-xs;
        @extend .pd-h-sm;
        border-radius: 50%;
        border: 2px solid var(--shade-04);

        & a {
          @extend .c-nm;

        }
      }
    }

  }


}
